.post {
	width: 50rem;
	position: relative;
	z-index: 30;
	border-radius: 1rem;
	margin: auto;
	margin-bottom: 2rem;
	padding-bottom: 0.5em;
	font-size: 1vmax;
	word-wrap: break-word;
	font-family: "mainFont";
	background-color: #1e1e1e;
	border: 1px solid #313131;
	transition: all 0.2s;
}
.post:hover {
	/* transform: scale(1.02); */
}
.commentBox {
	padding-left: 2rem;
	padding-right: 2rem;
}
.container {
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.creator {
	height: 5rem;
	padding-left: 2rem;
	padding-right: 2rem;
	text-align: left;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}
.creatorInfo {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-left: 1rem;
}
.creatorNick {
	font-size: 1rem;
	color: white !important;
	text-decoration: none;
}
.creatorNick:hover {
	text-decoration: underline !important;
}
.creatorWallet {
	margin-top: 0.08rem;
	font-size: 0.8rem;

	color: #f4d3a5;
}
.text {
	margin: 1rem 0;
	margin-left: 2rem;
	margin-right: 2rem;
	text-align: left;
	font-size: 1.2rem;
	color: white;
	list-style-position: inside;
}

.mediaContent {
	width: 80%;
	padding-top: 2%;
	margin: 0 auto;
	margin-bottom: 1rem;

	display: flex;
	justify-content: center;
}
.Buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: white;
	justify-content: right;
	padding-left: 2rem;
	padding-right: 2rem;
}
.image {
	width: 100%;
	height: 100%;
	border-radius: 1rem;
}
.viewerAction {
	height: 10%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.button {
	width: 12rem;
	height: 3rem;
	border-radius: 0.8rem;
	margin: 1rem 0;
	padding: 0.2rem;
	font-size: 1.25rem;
	text-align: center;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;

	color: #fff;

	cursor: pointer;
	user-select: none;
}
.buttonTip {
	composes: button;
	background: #f4d3a5;
	border: none;
	color: black;
	opacity: 0.7;
	margin-right: 1rem;
}
.buttonBlue {
	composes: button;
	background: #f4d3a5;
	color: black;
}

.buttonBlack {
	composes: button;
	background-color: black;
	border: 2px solid white;
}

.buttonMint {
	composes: button;
	background-color: #2e63fb;
}

.buttonOff {
	composes: button;
	background-color: gray;
	cursor: default;
}

.animation {
	/* animation */
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	transition-duration: 300ms;
}

.animation:hover {
	transform: scale(1.08);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.42);
}

@media only screen and (max-width: 1200px) {
	.post {
		width: 80vw;
	}

	.button {
		font-size: 1rem;
		width: 7.5rem;
	}
}

@media only screen and (max-width: 600px) {
	.post {
		width: 100%;
		/* border-left: none; */
		/* border-right: none; */
		border: none;
		border-radius: 0px;
	}

	.post:hover {
		transform: none;
	}
	.button {
		width: 8rem;
		font-size: 1rem;
	}
	.animation:hover {
		transform: none;
	}

	.creator {
		margin-top: 0px;
	}

	.creatorInfo {
		margin-left: 0.2rem;
	}

	.creatorWallet {
		font-size: 0;
		display: none;
	}

	.profilePicutre {
		transform: scale(0.8);
	}
}

/* Hide input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
/* End of Hide input arrows */
