.wrapper {
	position: sticky;
	top: 6rem;
	width: 100%;
	right: 0;
	z-index: 100;
}
.container {
	position: absolute;
	width: 20rem;
}

.menuItems {
	width: 20rem;
	color: black;
	display: flex;
	flex-direction: column;
	font-family: "mainFont";
}
.logo {
	width: 16vmax;
	height: auto;
	margin-left: 1rem;
	margin-top: 1rem;
	object-fit: contain;
}

.icon {
	width: 2rem;
	height: 2rem;
	margin-right: 1.5rem;
}

.action {
	margin-left: 1rem;
	margin-top: 2rem;
	display: flex;
	flex-direction: row;
	color: black;
	font-weight: bold;
	cursor: pointer;
}

.menuItem {
	font-size: 1.5rem;
	margin: 0px;
	font-weight: 300;
	color: white;
}
.actionGrey {
	composes: action;
	filter: grayscale(1);
	filter: invert(50%);
}
.animation {
	/* animation */
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	transition-duration: 300ms;
}

.animation:hover {
	transform: scale(1.05);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.42);
}

a:link {
	text-decoration: none;
}
@media only screen and (max-width: 1200px) {
	.wrapper {
		position: fixed;
		z-index: 50;
		bottom: 0;
		width: 100%;
		right: 0;
	}
	.header {
		display: none;
	}
	.container {
		position: absolute;
		bottom: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		z-index: 999;
	}

	.menuItems {
		margin: 0px !important;
		bottom: 0px;
		width: 100%;
		height: 5rem;
		background-color: #131313;
		margin-bottom: 2vmax;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		border-top: 0.1vmax solid RGBA(83, 83, 83, 0.5);
	}

	.logo {
		margin: 0px;
		width: 0vmax;
		height: auto;
		margin-left: 1vmax;
		align-self: left;
	}
	.icon {
		width: 4vmax;
		height: 4vmax;
		filter: invert(0);
	}
	.action {
		font-size: 0vmax;
		margin: 0px;
	}

	.menuItem {
		font-size: 0px;
	}
}
