.container {
	display: flex;
	flex-direction: row;
	z-index: 50;
	max-width: 40rem;
	align-items: center;
	position: sticky;
	top: 1rem;
	left: 2rem;
}
@media only screen and (max-width: 1200px) {
	.container {
		display: none;
	}
}
