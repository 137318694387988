.container {
	width: 50rem;
	margin: 6rem auto;
	padding: 1rem;
	position: relative;
	border-radius: 1.5rem;
	z-index: 40;
	background-color: #1e1e1e;
	border: 0.1vmax solid #535353;
	word-wrap: break-word;
	text-align: center;
}

.profilePicture {
	margin-top: 2rem;
	margin-bottom: 1.5rem;
	display: inline-block;
}

.usernameEditBox {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	margin-top: 1vmax;
}

.usernameEditButton {
	background-color: transparent;
	border: 0;
	color: white;
	background-color: black;
	border-radius: 50px;
	border: 2px solid white;
	margin-left: 2rem;

	width: 4rem;
	height: 2rem;

	cursor: pointer;
}

.usernameEditButton img {
	width: 100%;
}

.username {
	color: white !important;
	font-family: "Lato", sans-serif;
	font-size: 1rem !important;
	font-weight: 600;
}

.walletAddress {
	color: white;
	font-size: 1.1rem;
	margin-top: 0.5vmax;
}

.balance {
	margin: 1rem 1rem;
	font-size: 1rem;
	color: #fff;
}

.logOutButton {
	padding: 1rem 2rem;
	margin-top: 5rem;
	margin-bottom: 1rem;
	background-color: #f4d3a5;
	color: black;
	border-radius: 0.5rem;

	font-family: "Lato", sans-serif;
	font-weight: 600;
	font-size: 1.3rem;
	text-align: center;

	border: 0px;
}

.animation {
	/* animation */
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	transition-duration: 300ms;
}

.animation:hover {
	transform: scale(1.1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.42);
}

/* Popup */

.popup {
	background-color: #17161d;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1.6vmax;
	border: 0.1vmax solid #535353;
	width: 20vmax;
	height: 5vmax;
	box-shadow: 1px -1px 54px 11px rgba(0, 110, 236, 0.75);
}

.popupInput {
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	height: 2vmax;
	background-color: #050111;
	border: 0px;
	border-radius: 1.5vmax;
	color: #fff;
	font-size: 0.9vmax;
	padding-left: 0.5vmax;
	margin-left: 2vmax;
}

.popupTip {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30%;
	height: 2vmax;
	background-color: #2e63fb;
	color: #fff;
	font-size: 1vmax;
	font-weight: 600;
	border-radius: 1.5vmax;
	margin-left: 1vmax;
	margin-right: 2vmax;
	padding-left: 1vmax;
	padding-right: 1vmax;
	border: 0;
}

@media only screen and (max-width: 1200px) {
	.container {
		width: 100%;
	}

	.postStub {
		background-color: #17161d !important;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.walletAddress {
		display: none;
	}
}
