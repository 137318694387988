.container {
	width: 100%;
	margin-bottom: 1rem;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.textAreaContainer {
	box-sizing: border-box;
	width: 100%;
	border-radius: 0.5rem;
}

.textArea {
	box-sizing: border-box;

	width: 100%;
	min-height: 4.5rem;
	overflow: auto;

	color: black;
	background-color: #eee;

	align-items: flex-start;
	justify-content: flex-start;
	text-align: start;

	border: none;
	outline: none;
	border-radius: inherit;

	resize: none;
	font-family: "Lato", sans-serif;
	font-size: 0.9rem;

	padding: 0.8rem 5%;
}

.button {
	border-radius: 0.5rem;
	margin: 0.7rem 0;
	padding: 0.5em 1em;

	font-size: 1rem;
	font-family: "Lato", sans-serif;
	font-weight: 600;

	text-align: center;

	color: black;
	background: #f4d3a5;

	user-select: none;

	cursor: pointer;
}

.buttonDisabled {
	composes: button;
	background-color: #535353;
	cursor: not-allowed;
}
