.creator {
	text-align: left;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}
.container {
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}
.profContainer {
	height: 100%;
	aspect-ratio: 1/1;
}
.creatorInfo {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.userInfoContainer {
	width: 100%;
	margin-left: 0.75rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
}
.creatorNick {
	font-size: 0.9rem;
	font-weight: 400;
	font-family: "Lato", sans-serif;
	color: white;
	display: flex;
	align-items: center;
	text-decoration: none;
}
.creatorNick:hover {
	text-decoration: underline;
}
.creatorWallet {
	font-size: 0.7rem;
	font-family: "Lato", sans-serif;
	color: #535353;
	display: flex;
	align-items: center;
	justify-content: right;
}
.textContainer {
	margin-top: 0.7rem;
	color: white;
	border-radius: 1rem;
}
.text {
	padding-top: 0.5rem;
	padding-left: 2rem;
	text-align: left;
	font-size: 0.85rem;
	font-family: "Lato", sans-serif;
	color: white;
}

@media only screen and (max-width: 600px) {
	.creatorWallet {
		font-size: 0;
		visibility: hidden;
	}
}
