.popupContainer {
	display: flex;
	color: white;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 2rem;
	width: 50rem;
	height: 10rem;
	padding: 2vmax 0;
	background-color: #1e1e1e;
	border: 1px solid #313131;
}

.popupRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 95%;
	margin: 0.4rem;
	position: relative;
}

.popupInput {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64%;
	height: 4rem;
	background-color: white;
	border: 0px;
	border-radius: 1.5rem;
	color: #111;
	font-size: 1.8rem;
	padding-left: 1rem;
	margin-left: 1%;
	margin-right: 1%;
	border: 1px solid #F4D3A5;
	outline: none;
}

.popupTip {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45%;
	height: 4rem;
	background: #F4D3A5;
	color: black;
	font-size: 1.6rem;
	font-weight: 600;
	border-radius: 1.5rem;
	margin-left: 1%;
	margin-right: 1%;
	cursor: pointer;
}

.currencySelect {
	color: black;
	margin-left: 1%;
	margin-right: 1%;
	font-size: 1rem;
	border-radius: 1rem;
	width: 100%;
}

.currency {
	color: #ddd;
	display: flex;
	width: 20%;
	padding-left: 1%;
	padding-right: 1%;
	margin-left: 1%;
	margin-right: 1%;
	height: 4rem;
	font-size: 1.3rem;
	justify-content: center;
	align-items: center;
	/* background-color: #050111; */
	background-color: transparent;
	border: 0.1rem solid #535353;
	border-radius: 1.5rem;
}

.popupAmountButton {
	display: inline-block;
	border-radius: 0.8rem;

	margin: 0 1%;

	padding: 1%;

	line-height: 2.rem;
	font-size: 0.9rem;
	text-align: center;
	font-family: "Lato", sans-serif;
	font-weight: 600;

	display: flex;
	justify-content: center;
	align-items: center;

	color: #fff;

	cursor: pointer;
	user-select: none;

	width: 15rem;
	height: 2.5rem;
	border: 0.1rem solid #535353;
	background-color: #050111;
	color: white;
}

.maxButton {
	position: absolute;
	right: 56%;
	background: none;
	border: none;
	text-decoration: underline;
	font-size: 1rem;
}
