.container {
	margin: 2rem auto;
	margin-bottom: 2rem;
	color: black;
	z-index: 20;
}
.JourneyText {
	text-align: left;
	font-size: 1.5rem;
}
.JourneyPost {
	width: 50rem;
	margin-top: 1vmax;
	padding: 1.5rem 0;
	min-height: 20rem;
	background-color: #1e1e1e;
	border: 1px solid #313131;
	border-radius: 1rem;
}

.InfoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;

	width: 90%;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.userInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 1rem;
}

.userNickname {
	font-size: 1rem;
	color: white;
}

.userAddress {
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #f4d3a5;
}
.MediaContent {
	width: 90%;
	height: auto;
	background-color: #eee;
	/* border: 1px solid black; */
	border-radius: 1rem;
	margin: auto;
	resize: none;
	color: black;
}
.textareaStyling {
	min-height: 3vmax;
	padding: 1rem;
	resize: none;
	background-color: transparent;
	color: black;
	font-size: 1rem;
	width: 100%;
	outline: none;
	min-height: 7.5rem;
	border: none;
	overflow: auto;
	font-family: "mainFont";
}

::placeholder {
	color: #41414b;
	opacity: 1;
}

.buttons {
	width: 90%;
	padding-top: 0.5vmax;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 67%;
}

.buttonMedia {
	height: 5vmax;
	width: 48%;
	box-sizing: border-box;
	background-color: #050111;
	border-radius: 0.75vmax;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.buttonIcon {
	height: 95%;
}

.buttonCreate {
	height: 2.5rem;
	width: 30%;
	font-size: 1.2rem;
	border-radius: 0.75vmax;
	background: #f4d3a5;
	color: black;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.fileName {
	width: 89%;
	margin: 0 auto;
	margin-top: 0.5rem;
}

.animation {
	/* animation */
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	transition-duration: 300ms;
}

.animation:hover {
	transform: scale(1.05);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.42);
}

@media only screen and (max-width: 1200px) {
	.JourneyPost {
		width: 80vw;
	}
}

@media only screen and (max-width: 600px) {
	.JourneyPost {
		height: fit-content !important;
		margin-top: 4vmax;
		margin-bottom: 2vmax;
		width: 100%;
		/* border-left: none; */
		/* border-right: none; */
		border: none;
		border-radius: 0px;
		padding-bottom: 1vmax;
	}

	.textareaStyling {
		font-size: 1rem;
		min-height: 5rem;
	}

	.buttonsContainer {
		width: 100%;
	}

	.buttonCreate {
		margin: 1.3vmax auto;
		width: 100%;
		font-size: 1.5rem;
	}

	.buttons {
		margin-top: 0.7vmax;
		display: block;
	}

	.container {
		width: 100%;
		margin-top: 0.5rem;
		margin-bottom: 0rem;
	}

	.JourneyText {
		font-size: 0rem;
		visibility: hidden;
	}

	.userAddress {
		display: none;
	}

	.userInfo {
		margin-left: 0.2rem;
	}

	.profilePicutre {
		transform: scale(0.8);
	}
}
