.mainPage {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin-top: 1vmax;
	z-index: 20;
}
.container {
	margin: 2rem auto;
	margin-bottom: 2rem;
	color: white;
}
.JourneyText {
	text-align: left;
	font-size: 1.5rem;
}
.JourneyPost {
	width: 50rem;
	margin-top: 1vmax;
	padding: 1.5rem;
	min-height: 14rem;

	background-color: #131313;
	border: 2px solid #3e3e3e;

	border-radius: 1.5vmax;
}

@media only screen and (max-width: 1200px) {
	.mainPage {
		margin-bottom: 1.5rem;
	}
}

@media only screen and (max-width: 1200px) {
	.JourneyPost {
		width: 80vw;
	}
}

@media only screen and (max-width: 600px) {
	.JourneyPost {
		height: fit-content !important;
		margin-top: 4vmax;
		margin-bottom: 2vmax;
		width: 100%;
		/* border-left: none; */
		/* border-right: none; */
		border: none;
		border-radius: 0px;
		padding-bottom: 1vmax;
	}

	.container {
		width: 100%;
		margin-top: 0.5rem;
		margin-bottom: 0rem;
	}
}
