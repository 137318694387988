.wrapper {
	position: sticky;
	margin-top: -5.5rem;
	top: 0rem;
	width: 100%;
	height: 5rem;
	background-color: #131313;
	z-index: 40;
}
.container {
	display: flex;
	justify-content: right;
	align-items: center;
	position: absolute;
	right: 1rem;
	top: 0.5rem;
}

.loginButton {
	width: 15rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 5rem;
	border: none;
	border-radius: 1rem;
	background: linear-gradient(90deg, #ffb300 35%, #ff7a00);
	font-size: 2rem;
	text-decoration: none;
	color: #ffffff;
	font-weight: bold;
	font-family: "mainFont";
	padding: 1em;
}
.dappLink {
	margin-right: 2rem;
	color: white;
	font-size: 1.25rem;
	z-index: 40;
}
.chainDisplayBox {
	color: #41414b;
	border: solid 0.15vmax #41414b;
	border-radius: 100vmax;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0vmax 0.5vmax;
	margin-right: 1vmax;
}

.chainDot {
	width: 0.3rem;
	height: 0.3rem;
	border-radius: 50%;
	aspect-ratio: 1/1;
	margin-right: 0.25rem;
}

.chainName {
	font-size: 1rem;
	margin: 0.5vmax 0vmax;
}

.animation {
	/* animation */
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	transition-duration: 300ms;
}

.animation:hover {
	transform: scale(1.1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.42);
}

a:link {
	text-decoration: none;
	color: #ffffff;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

a:active {
	text-decoration: none;
}
.action:link {
	text-decoration: none;
}
@media only screen and (max-width: 1200px) {
	.wrapper {
		top: 0;
		width: 100%;
		display: none;
	}
	.container {
		z-index: 9999;
		display: flex;
		right: 0;
		justify-content: center;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		background-color: #131313;
		width: 100%;
		position: absolute;
	}

	.chainDisplayBox {
		display: none;
	}

	.loginButton {
		font-size: 2rem;
		padding: 1.1rem;
	}
	.loginButton {
		width: 7.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 3rem;
		border: none;
		border-radius: 1rem;
		background: linear-gradient(90deg, #ffb300 35%, #ff7a00);
		font-size: 1rem;
		text-decoration: none;
		color: #ffffff;
		font-weight: bold;
		font-family: "mainFont";
	}
}
@media only screen and (max-width: 800px) {
	.profilePictureButton {
		display: none;
	}
	.dappLink {
		margin-right: 1rem;
		color: white;
		font-size: 1.2rem;
	}
}
