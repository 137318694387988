.container {
	width: 80%;
	height: auto;

	margin: 1.5em auto;

	background-color: #1e1e1e;
	border: 1px solid #313131;

	word-wrap: break-word;
	/* border: 0.1vmax solid #535353; */
	border-radius: 1.5vmax;
	padding: 1rem;
}

.mediaContent {
	width: 100%;
	border-radius: 1vmax;
	margin-bottom: 0.5rem;
}

.text {
	color: black;
	font-size: 1.3rem;
	padding: 0.2rem 0.8rem;
	text-align: left;
	margin-bottom: 1rem;
	list-style-position: inside;
}

.bottomPostContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.deleteButtonContainer {
	margin-left: 0.8rem;
	margin-bottom: 0.5rem;
	flex-basis: 100%;
	display: flex;
}

.deleteButtonContainer button {
	background-color: #ffb508;
	color: #fff;
	border: none;
	border-radius: 0.8vmax;

	padding: 0.2rem 0.6rem;
	font-weight: bold;
	font-size: 1rem;
	font-family: "Lato", "Helvetica", "Arial";

	width: 10rem;
}

.timeAdded {
	color: #535353;
	text-align: left;
	padding: 0.2rem 0.8rem;
	font-size: 1rem;
}

.chain {
	margin-left: 0.8rem;
}

@media only screen and (max-width: 600px) {
	.container {
		width: 100%;
		border-radius: 0;
		padding: 1.7rem;
	}
}
