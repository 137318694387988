.wrapper {
	display: none;
}

@media only screen and (max-width: 1200px) {
	.wrapper {
		display: block;
		position: fixed;
		right: 2rem;
		top: 2rem;
		z-index: 100;
	}
	.container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		background-color: #131313;
	}
	.dappLink {
		color: white;
		font-size: 3rem;
		margin-left: 2rem;
		z-index: 40;
	}
	.listElement {
		margin-top: 1.5rem;
	}
}
