.container {
	text-align: end;
}

.showHideButton {
	all: unset;
	color: #7b7b7b;
	font-size: 0.9rem;
	margin: 0.3rem;
	padding-right: 2rem;
	margin-top: 0.9rem;
	margin-bottom: 0.6rem;
	text-decoration: underline;
	cursor: pointer;
}
