.mainPage {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
	.mainPage {
		margin-bottom: 1.5rem;
	}
}
